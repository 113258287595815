<template>
    <div>
         <b-container>
            <section class="text-center advertise">
                <h2 class="main-title">Payment gateway for Branding Pavilion main advert</h2>
                <h4 class="custom-subtitle">Advertisement banner preview</h4>
                <div class="advertise__main-banner"></div>
                <button @click.prevent="buyMainAdvert" class="details-button details-button--green mt-4"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Buy advertisement $800</button>
                <div id="verificationGateway"></div>
            </section>
        </b-container>
    </div>
</template>

<script>
import SimpleLineIcons from 'vue-simple-line'
import Vue from 'vue'
import router from '../router/router'
const FIREBASE_FUNCTION_BUY_MAIN_ADVERT = process.env.VUE_APP_FIREBASE_FUNCTION_BUY_MAIN_ADVERT
const PUBLISHABLE_KEY = process.env.VUE_APP_PUBLISHABLE_KEY
export default {
  components: {
    SimpleLineIcons
  },
  methods: {
    buyMainAdvert () {
      Stripe.setPublishableKey(PUBLISHABLE_KEY)
      var handler = StripeCheckout.configure({
        key: PUBLISHABLE_KEY,
        image: require('@/assets/images/shared/icon-1.png'),
        locale: 'auto',
        token: function (token) {
          doPayment(token).then((result) => {
            document.getElementById('gatewayIframe').style.display = 'none';
            (async function () {
              const res = await fetch(FIREBASE_FUNCTION_BUY_MAIN_ADVERT, {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify({
                  result
                })
              })
              const data = await res.json()
              if (data.statusCode === 200) {
                Vue.swal({
                  type: 'success',
                  title: 'Transaction successfully completed!',
                  text: 'You have successfully bought advert. We will verify your transaction and put your advert on our website as soon as possible. Notice that this process can take up to 48 hours. For more details, please contact us directly.',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  if (result.value) {
                    router.push('/')
                  }
                })
              } else {
                Vue.swal({
                  type: 'error',
                  title: 'Error occurred',
                  text: 'Please try again later',
                  confirmButtonText: 'OK'
                })
              }
            }())
          }).catch((error) => {
            console.error(error)
            document.getElementById('gatewayIframe').style.display = 'none'
            Vue.swal({
              type: 'error',
              title: 'Error occurred',
              text: '3D Secure authentication failed',
              confirmButtonText: 'OK'
            })
          })

          function doPayment (token) {
            return new Promise((resolve, reject) => {
              const onCreateCardCallback = create3DSecure(token, resolve, reject)
              return Stripe.source.create({
                type: 'card',
                token: token.id
              }, onCreateCardCallback)
            })
          }

          function create3DSecure (token, resolve, reject) {
            return (status, cardResponse) => {
              if (status !== 200 || cardResponse.error) {
                reject(cardResponse.error)
              } else if (cardResponse.card.three_d_secure === 'not_supported' && cardResponse.status === 'chargeable') {
                resolve(cardResponse)
              } else if (cardResponse.card.three_d_secure === 'optional' || cardResponse.card.three_d_secure === 'required') {
                const onCreate3DSecureCallback = createIframe(token, resolve, reject)

                Stripe.source.create({
                  type: 'three_d_secure',
                  amount: 80000,
                  currency: 'usd',
                  three_d_secure: {
                    card: cardResponse.id
                  },
                  redirect: {
                    return_url: window.location.href
                  }
                }, onCreate3DSecureCallback)
              } else {
                reject(cardResponse)
              }
            }
          }

          function createIframe (token, resolve, reject) {
            return (status, stripe3dsResponse) => {
              if (status !== 200 || stripe3dsResponse.error) {
                reject(stripe3dsResponse.error)
              } else {
                document.getElementById('verificationGateway').innerHTML = '<iframe id="gatewayIframe" frameborder="0" src="' + stripe3dsResponse.redirect.url + '"></iframe>'

                const onPollCallbackReal = onPollCallback(token, resolve, reject)
                Stripe.source.poll(stripe3dsResponse.id, stripe3dsResponse.client_secret, onPollCallbackReal)
              }
            }
          }

          function onPollCallback (token, resolve, reject) {
            return (status, source) => {
              if (status !== 200 || source.error) {
                reject(source.error)
              } else if (source.status === 'canceled' || source.status === 'consumed' || source.status === 'failed') {
                reject(source.status)
              } else if (source.status === 'chargeable') {
                resolve(source)
              }
            }
          }
        }
      })

      handler.open({
        name: 'Branding Pavilion',
        description: 'Main advert',
        amount: 80000,
        currency: 'usd'
      })

      // Close Checkout on page navigation:
      window.addEventListener('popstate', function () {
        handler.close()
      })
    }
  }
}
</script>
